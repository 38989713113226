$primaryColor: #282c34;
$secondaryColor: #e8007d;
$primaryColorAlpha: rgba(40, 44, 52, 0.68);
$secondaryColorAlpha: rgba(252, 0, 125, 0.3);

::selection {
  background: $secondaryColorAlpha; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $secondaryColorAlpha; /* Gecko Browsers */
}

.App {
  text-align: center;
}

h1 {
  color:$primaryColor;
}

h2 {
  color:$primaryColor;
}

.App-logo {
  height: 40vmin;
}

.min-logo {
  height: 4em;
}

.clickable {
  cursor: pointer;
}

span {
  &.clickable {
    &:hover {
      text-decoration: underline;
    }
  }
}

.App-header {
  background-color: $primaryColorAlpha;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  color: white;
  &:before {
    z-index: -1;
    content:"";
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image: url('./pictures/bg.png');
    background-size: cover;
  }
}

.App-demi-header {
  h2 {
    color: white;
  }
  padding-top: 1em;
  background-color: $primaryColor;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  p {
    margin-top: 10px;
    margin-bottom: 39px;
    font-size: 16px;
  }
  a {
    color:white;
    text-decoration: none;
    &:hover {
      color:$secondaryColor;
      text-decoration: underline;
    }
  }
}

.App-link {
  color: #61dafb;
}

#blob {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 21em;
  .linkContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 1;
    padding: 32px;
    box-sizing: border-box;
    button {
      display:block;
      cursor: pointer;
      color: white;
      background-color: transparent;
      border: 1px solid white;
      border-radius: 50px;
      margin: auto;
      padding: 14px 60px;
      font-size: 18px;
      font-weight: 600;
      transition-duration: 500ms;
      transition-property: color, background-color;
      &:hover {
        background-color: white;
        color: $secondaryColor;
      }
      &:focus {
        outline: none;
      }
    }
  }
  canvas {
    width: 100%!important;
    max-width: 900px;
    min-width: 900px;
    z-index: 0;
    height: auto!important;
  }
}

.formRow {
  background-color: $primaryColor;
  padding: 10px 10px;
  transition-property: height;
  transition-duration: 1s;
  overflow: hidden;
  h1 {
    color:white;
  }
  .sendedMail {
    button {
      height:50px;
      padding: 0 50px;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      background-color: #57ad57;
      border: none;
      font-size: 15px;
      &:hover {
        content:"Refaire une demande"
      }
    }
  }
  .form {
    max-width: 30em;
    margin: auto;
    .errorRow {
      text-align: right;
      font-size: 12px;
      position: relative;
      top: 17px;
      padding: 0 5px;
      .error {
        color:$secondaryColor;
      }
      .info {
        color:$primaryColor;
      }
    }
    .globalError {
      margin-bottom: 1em;
      color: orange;
    }
    .loader {
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 50%;
      margin: auto;
      border: 4px solid $primaryColor;
      border-top: 4px solid transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      animation-name: rotate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes rotate {
      from {transform: rotate(0deg)}
      to {transform: rotate(360deg)}
    }
    button {
      height:50px;
      padding: 0 50px;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      background-color: $primaryColor;
      border: none;
      font-size: 15px;
    }
    margin-bottom: 16px;
  }
}

.InfoLegals {
  background-color: $primaryColor;
  padding: 16px;
  color:white;
  font-size: 14px;
  .flatico {
    font-size: 6px;
    color: #3e4856;
    a {
      color: #3e4856;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ico {
  width: 1em;
  position: relative;
  top: 3px;
}

.descriptionTextContainer {
  background-color: white;
  .descriptionTextRow {
    max-width: 50em;
    margin: auto;
    padding: 32px;
    h1 {
      max-width: 500px;
      display: block;
      margin: auto;
      margin-bottom: 32px;
      margin-top: 16px;
    }
    h2 {
      margin-top: 64px;
    }
  }
  .doubleDash {
    color:$secondaryColor;
    font-weight: bold;
  }
  a {
    color:grey;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color:$secondaryColor;
    }
  }
  .superview {
    font-weight: bold;
    color: $secondaryColor;
  }
}

.addressBox {
  display: flex;
  @media screen and (max-width: 600px){
    display: block;
  }
  margin-bottom: -3px;
  background-color: white;
  .addressTextRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    padding: 32px 32px;
    a {
      color:grey;
      text-decoration: none;
      &:hover {
        color:$secondaryColor;
        text-decoration: underline;
      }
    }
  }
  .map {
    min-width: 10em;
    flex-grow: 1;
  }
}